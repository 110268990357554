import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [

  {
    sousId: 0,
    stakingToken: tokens.cngs,
    earningToken: tokens.cngs,
    contractAddress: {
      97: '0xbc528bf62098722ed9df9e41575646c798f834c2',
      56: '0xbc528bf62098722ed9df9e41575646c798f834c2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '30',
    sortOrder: 1,
    isFinished: false,
  },
  
  
  
]

export default pools
