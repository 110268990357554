import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'CNGS',
    lpAddresses: {
      97: '0xf598327fd37255a4056e38a4a3a2fa5812ee2cda',
      56: '0xf598327fd37255a4056e38a4a3a2fa5812ee2cda',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
 
  {
    pid: 1,
    lpSymbol: 'CNGS-BNB LP',
    lpAddresses: {
      97: '0xec5e5279b858130ea5fdf9442953aef0c5063e20',
      56: '0xec5e5279b858130ea5fdf9442953aef0c5063e20',
    },
    token: tokens.cngs,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0x383df876ee44b72b3dbedd8d0a9b60e41f2c8d97',
      56: '0x383df876ee44b72b3dbedd8d0a9b60e41f2c8d97',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
   
  
  
  
   
  
  
   
	  
	
// finished last  2  
    

]

export default farms
