import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
   
	
		
   {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0xf598327fd37255a4056e38a4a3a2fa5812ee2cda',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
 
 
   {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  }, 
 
	{
	label: t('Docs'),
	icon: 'InfoIcon',
	href: 'https://docs.countingswap.com/',
	}, 
  
 

  
       
 
	
	
	
	
	

]

export default config
